import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Terms from '../components/terms'
import { ParallaxProvider } from 'react-scroll-parallax'

const PricingPage = ({ className }) => {
  const lang = 'en'
  return (
    <Layout lang={lang}>
      <SEO title="Terms and Condition" />
      <ParallaxProvider>
        <Terms />
      </ParallaxProvider>
    </Layout>
  )
}

export default PricingPage
